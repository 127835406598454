<template>
    <div>
        <v-container fluid class="mt-10" v-if="mainOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container v-else>
            <v-overlay :value="loadingOverlay" color="white">
                <v-progress-circular
                    color="wellis"
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
            <v-row>
                <v-col cols="12" md="12" sm="12">
                    <v-card>
                        <v-card-title>
                            <img :src="require('../../../assets/img/logo.svg')" width="186">
                        </v-card-title>
                        <v-card-text style="font-size:15px">
                            <v-divider class="mb-3"></v-divider>
                            <v-row>
                                <v-col cols="12" xl="4" sm="12">
                                    <h3 class="mb-3" style="color:transparent">-</h3>
                                    <div>
                                        <span class="font-weight-bold">{{$t('v3.components.my-orders.orderNo') | capitalize}}:</span>
                                        <span class="wellis--text ml-1 font-weight-bold">{{order.order_no}}</span>
                                    </div>

                                    <div class="mt-2">
                                        <span class="font-weight-bold">{{$t('components.my-orders.date') | capitalize}}:</span>
                                        <span class="ml-1">{{order.date_entered | formatDate}}</span>
                                    </div>

                                    <div class="mt-2">
                                        <span class="font-weight-bold">{{$t('components.administration.category.status') | capitalize}}:</span>
                                        <span class="wellis--text ml-1 font-weight-bold">{{$t(`components.my-orders.status.${order.objstate}`)}}</span>
                                    </div>

                                    <div class="mt-2">
                                        <span class="font-weight-bold">{{$t('components.checkout.notes') | capitalize}}:</span>
                                        <span class="ml-1 font-italic" v-html="nl2br(order.note_text)"></span>
                                    </div>
                                </v-col>

                                <v-col cols="12" xl="4" sm="12">
                                    <h3 class="mb-3 font-italic">{{$t('components.checkout.shipping')}}</h3>
                                    <div>
                                        <span class="font-weight-bold">{{$t('components.my-orders.details.customer')}}:</span>
                                        <span class="wellis--text ml-1 font-weight-bold">{{order.customer_name}}</span>
                                    </div>

                                    <div class="mt-2">
                                        <span class="font-weight-bold">{{$t('components.my-orders.details.deliveryType')}}:</span>
                                        <span class="wellis--text ml-1 font-weight-bold">{{order.delivery_method}}</span>
                                    </div>

                                    <div class="mt-2">
                                        <span class="font-weight-bold">{{$t('components.my-orders.details.deliveryTerms')}}:</span>
                                        <span class="wellis--text ml-1 font-weight-bold">{{order.delivery_terms}}</span>
                                    </div>

                                    <div class="mt-2">
                                        <span class="font-weight-bold">{{$t('components.my-orders.details.shippingAddress')}}:</span>
                                        <span class="ml-1 font-italic" v-html="nl2br(order.ship_addr)"></span>
                                    </div>
                                </v-col>

                                <v-col cols="12" xl="4" sm="12">
                                    <h3 class="mb-3 font-italic">{{$t('components.billing.title')}}</h3>
                                    <div>
                                        <span class="font-weight-bold">{{$t('components.my-orders.details.customer')}}:</span>
                                        <span class="wellis--text ml-1 font-weight-bold">{{order.customer_name}}</span>
                                    </div>

                                    <div class="mt-2">
                                        <span class="font-weight-bold">{{$t('components.billing.vatNo')}}:</span>
                                        <span class="wellis--text ml-1 font-weight-bold">{{order.vat_no || '-'}}</span>
                                    </div>

                                    <div class="mt-2">
                                        <span class="font-weight-bold">{{$t('components.my-orders.details.paymentTerms')}}:</span>
                                        <span class="wellis--text ml-1 font-weight-bold">{{order.pay_term_id || '-'}}</span>
                                    </div>

                                    <div class="mt-2">
                                        <span class="font-weight-bold">{{$t('components.my-orders.details.billingAddress')}}:</span>
                                        <span class="ml-1 font-italic" v-html="nl2br(order.bill_addr_no)"></span>
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row class="mt-5">
                                <v-col cols="12" sm="12">
                                    <h4 class="mb-3">{{$t('components.products.title')}}</h4>
                                    <v-progress-linear v-if="itemsLoading"
                                                       indeterminate
                                                       color="wellis"
                                    ></v-progress-linear>
                                    <v-simple-table v-else>
                                        <thead>
                                        <tr>
                                            <th class="text-uppercase small">{{$t('components.products.catalogNo') | capitalize}}</th>
                                            <th class="text-uppercase small">{{$t('components.my-orders.catalogDesc') | capitalize}}</th>
                                            <th class="text-uppercase small">{{$t('components.stocklist.configNo') | capitalize}}</th>
                                            <th class="text-uppercase small">{{$t('components.products.quantity') | capitalize}}</th>
                                            <th class="text-uppercase small">{{$t('components.administration.category.status') | capitalize}}</th>
                                            <th class="text-uppercase small">{{$t('components.my-orders.plannedDate') | capitalize}}</th>
                                            <th class="text-uppercase small">{{$t('components.administration.orders.concrete.unitPrice') | capitalize}}</th>
                                            <th class="text-uppercase small">{{$t('components.products.price') | capitalize}}</th>
                                        </tr>
                                        </thead>
                                        <tbody v-if="orderItems !== null && orderItems.length === 0">
                                        <tr>
                                            <td colspan="7" class="wellis--text">
                                                {{$t('components.my-orders.details.noTableData')}}
                                            </td>
                                        </tr>
                                        </tbody>
                                        <tbody v-else>
                                        <tr v-for="(item, index) in orderItems" :key="item.catalog_no+'_'+index">
                                            <td class="wellis--text font-weight-bold">{{item.catalog_no}}</td>
                                            <td class="wellis--text">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span v-on="on" v-bind="attrs" class="wellis--text">{{item.catalog_desc | cut(30)}}</span>
                                                    </template>
                                                    <span>{{item.catalog_desc}}</span>
                                                </v-tooltip>
                                            </td>
                                            <td>
                                                <v-btn text v-if="item.configuration_id !== '*' && item.configuration_id !== null"
                                                       @click="getConfiguration(item.catalog_no, item.part_no, item.configuration_id)">
                                                    <v-icon>mdi-magnify</v-icon>
                                                    {{item.configuration_id}}
                                                </v-btn>
                                                <v-btn text v-else>
                                                    <v-icon style="color:transparent">mdi-magnify</v-icon>
                                                    {{item.configuration_id}}
                                                </v-btn>
                                            </td>
                                            <td>{{item.qty}}</td>
                                            <td>
                                                <span :class="item.objstate === 'Released' ? 'wellis--text font-weight-bold font-italic' : ''">
                                                    {{$t(`components.my-orders.status.${item.objstate}`)}}
                                                </span>
                                            </td>
                                            <td>
                                                <span v-if="item.planned_delivery_text === '-'">-</span>
                                                <span v-else>{{$t('components.shopord.readyWeek', {num: item.planned_delivery_text})}}</span>
                                            </td>
                                            <td v-if="item.amount !== null">{{item.amount | toPrice(item.currency, false)}}</td>
                                            <td v-else>-</td>

                                            <td v-if="item.amount !== null">{{(item.amount * item.qty) | toPrice(item.currency, false)}}</td>
                                            <td v-else>-</td>
                                        </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-col>
                            </v-row>

                            <v-divider class="mt-3 mb-3"></v-divider>

                            <v-row class="mt-5">
                                <v-col cols="12" sm="12">
                                    <h4 class="mb-3">{{$t('components.my-invoices.title')}}</h4>
                                    <v-progress-linear v-if="documentsLoading"
                                                       indeterminate
                                                       color="wellis"
                                    ></v-progress-linear>
                                    <v-simple-table v-else>
                                        <thead>
                                        <tr>
                                            <th class="text-uppercase small">{{$t('components.my-invoices.invoiceNo') | capitalize}}</th>
                                            <th class="text-uppercase small">{{$t('components.my-invoices.grossAmount') | capitalize}}</th>
                                            <th class="text-uppercase small">{{$t('components.my-invoices.remainingAmount') | capitalize}}</th>
                                            <th class="text-uppercase small">{{$t('components.administration.category.status') | capitalize}}</th>
                                            <th class="text-uppercase small">{{$t('components.my-orders.date') | capitalize}}</th>
                                            <th class="text-uppercase small">{{$t('components.my-invoices.typeInvoice') | capitalize}}</th>
                                            <th class="text-uppercase small">{{$t('components.my-invoices.typeCmr') | capitalize}}</th>
                                            <th class="text-uppercase small">{{$t('components.my-invoices.typeDeliveryNote') | capitalize}}</th>
                                        </tr>
                                        </thead>
                                        <tbody v-if="invoices !== null && invoices.length === 0">
                                        <tr>
                                            <td colspan="8" class="wellis--text">
                                                {{$t('components.my-orders.details.noTableData')}}
                                            </td>
                                        </tr>
                                        </tbody>
                                        <tbody v-else>
                                        <tr v-for="(item, index) in invoices" :key="item.invoice_no+'_'+index">
                                            <td class="wellis--text font-weight-bold">{{item.invoice_no}}</td>
                                            <td>{{item.gross_amount | toPrice(item.currency, false)}}</td>
                                            <td> {{item.remaining | toPrice(item.currency, false)}}</td>
                                            <td>
                                                <span v-if="item.remaining !== 0" class="font-weight-bold font-italic">
                                                    {{$t(`components.my-invoices.status.unpaid`)}}
                                                </span>
                                                <span v-else class="wellis--text font-weight-bold font-italic">{{$t(`components.my-invoices.status.paid`)}}</span>
                                            </td>
                                            <td>{{item.invoice_date | formatDate}}</td>
                                            <td>
                                                <v-btn small text :color="item.invoice === null ? '' : 'wellis'" :disabled="item.invoice === null"
                                                       @click="downloadDocument(item.invoice)">
                                                    <v-icon>mdi-download</v-icon>
                                                </v-btn>
                                            </td>
                                            <td>
                                                <v-btn small text :color="item.cmr === null ? '' : 'wellis'" :disabled="item.cmr === null"
                                                       @click="downloadDocument(item.cmr)">
                                                    <v-icon>mdi-download</v-icon>
                                                </v-btn>
                                            </td>
                                            <td>
                                                <v-btn small text :color="item.szall === null ? '' : 'wellis'" :disabled="item.szall === null"
                                                       @click="downloadDocument(item.szall)">
                                                    <v-icon>mdi-download</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog
            v-model="configurationDialog"
            max-width="650px"
            @click:outside="configurationDialog = false"
        >
            <v-card>
                <v-card-title>
                    {{$t('v3.components.cart.btnViewConfig')}}
                </v-card-title>
                <v-card-text v-html="configurationData || ''">
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {EVENT_LANG_CHANGE, EVENT_PAGE_TITLE_CHANGE} from '../../../core/constants';
import AccountApiService from '../../../api/account.api';
import ConfiguratorApiService from '../../../api/configurator.api.service';
import {handleDownloadedFile, nl2br} from '../../../helpers';
import MediaApiService from '../../../api/media.api';

export default {
    name: 'MyOrderDetails',

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'components.my-orders.title', this.$route.params.orderNo), 100);

        if (!this.hasLiteUserPermission('my_orders')) {
            this.$ApplGeneral({
                content: this.$t('v32.liteUser.hasNoPermission'),
                onClose: function() {
                    this.$router.push({
                        name: 'app.start',
                    });
                },
            });
            return;
        }

        this.fetch();
        this.$eventBus.$on(EVENT_LANG_CHANGE, () => {
            this.fetch();
        });
    },

    destroyed() {
        this.$eventBus.$off(EVENT_LANG_CHANGE);
    },

    data() {
        return {
            mainOverlay: true,
            loadingOverlay: false,

            configurationDialog: false,
            configurationData: null,

            itemsLoading: false,
            documentsLoading: false,

            order: null,
            orderItems: null,
            configCache: {},
            invoices: null,
        };
    },

    methods: {
        hasLiteUserPermission(permission) {
            if (!this.$store.getters['authStore/isLiteUser']) {
                return true;
            }
            let permission_ = this.$store.getters['authStore/getLiteUserPermission'].find(x => x.permission === permission);
            return permission_.objstate === 'ACTIVE';
        },

        fetch() {
            this.loadingOverlay = true;
            this.itemsLoading = true;
            this.documentsLoading = true;
            AccountApiService.orders.concrete.header(this.$route.params.orderNo)
                .then(response => {
                    this.order = response.data;
                    this.getOrderItems();
                    this.getInvoices();
                })
                .catch(() => {
                    this.$snackBar({
                        icon: 'mdi-alert',
                        allowOutsideClick: false,
                        text: this.$t('api-error'),
                    });
                })
                .finally(() => {
                    this.mainOverlay = false;
                    this.loadingOverlay = false;
                });
        },

        async getOrderItems() {
            this.itemsLoading = true;
            AccountApiService.orders.concrete.items(this.$route.params.orderNo)
                .then(response => this.orderItems = response.data)
                .catch(() => {
                    this.$snackBar({
                        icon: 'mdi-alert',
                        allowOutsideClick: false,
                        text: this.$t('api-error'),
                    });
                })
                .finally(() => {
                    this.itemsLoading = false;
                });
        },

        async getInvoices() {
            if (this.hasLiteUserPermission('my_invoices')) {
                this.documentsLoading = true;
                AccountApiService.orders.concrete.invoices(this.$route.params.orderNo)
                    .then(response => this.invoices = response.data.items)
                    .catch(() => {
                        this.$snackBar({
                            icon: 'mdi-alert',
                            allowOutsideClick: false,
                            text: this.$t('api-error'),
                        });
                    })
                    .finally(() => {
                        this.documentsLoading = false;
                    });
            } else {
                this.documentsLoading = false;
            }
        },

        nl2br(string) {
            return nl2br(string);
        },

        getConfiguration(catalogNo, partNo, configNo) {
            if (configNo !== '*' && configNo !== null) {
                const cacheKey = `${catalogNo}_${partNo}_${configNo}_${this.$store.getters['langStore/current']}`;
                this.loadingOverlay = true;
                if (cacheKey in this.configCache) {
                    this.viewConfiguration(this.configCache[cacheKey]);
                } else {
                    ConfiguratorApiService.autoConfigureById(catalogNo, partNo, configNo)
                        .then(response => {
                            this.configCache[cacheKey] = response.data.configuration;
                            this.viewConfiguration(this.configCache[cacheKey]);
                        })
                        .catch(() => {
                            this.$snackBar({
                                icon: 'mdi-alert',
                                allowOutsideClick: false,
                                text: this.$t('api-error'),
                            });
                            this.loadingOverlay = false;
                        });
                }
            }
        },

        viewConfiguration(data) {
            let body = '<div class="v-data-table theme--light"><div class="v-data-table__wrapper"><table>'
                + '<tbody>';
            Object.values(data).forEach(line => {
                let value = line.values.find(x => line.selectedValue === x.optionValueId);
                if (value) {
                    value = value.description;
                }
                body += `<tr><td class="font-weight-bold">${line.description}</td><td class="wellis--text font-weight-bold">${value || line.selectedValue}</td></tr>`;
            });

            body += '</div></div>';
            this.configurationData = body;
            this.configurationDialog = true;
            this.loadingOverlay = false;
        },

        downloadDocument(documentId) {
            this.loadingOverlay = true;
            MediaApiService.ifs.downloadDocument(documentId)
                .then(blobFile => {
                    return handleDownloadedFile(blobFile.data, blobFile.headers['content-disposition'].replace('attachment; filename=', ''), blobFile.type);
                })
                .catch(() => {
                    this.$snackBar({
                        icon: 'mdi-alert',
                        allowOutsideClick: false,
                        text: this.$t('api-error'),
                    });
                })
                .finally(() => {
                    this.loadingOverlay = false;
                });
        },
    },
};
</script>

<style scoped>

</style>
